import { TypographyProps } from '@mui/material/Typography/Typography';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface CopyrightProps extends TypographyProps {
  isShort?: boolean;
}

const Copyright = ({ isShort }: CopyrightProps) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ pt: 4, textTransform: 'capitalize' }}>
      {isShort ? (
        <>
          {'© '}
          {new Date().getFullYear()}
        </>
      ) : (
        <>
          {`${t('app.copyright')} © Off Prize `}
          {new Date().getFullYear()}
          {'.'}
        </>
      )}
    </Typography>
  );
};

export default Copyright;
