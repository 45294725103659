import * as React from 'react';
import LeftMenu from './left-menu/LeftMenu';
import TopMenu from './top-menu/TopMenu';

interface NavigationsProps {
  title?: string;
}

const Navigations = ({ title }: NavigationsProps) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <TopMenu open={open} toggleDrawer={toggleDrawer} title={title} />
      <LeftMenu open={open} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Navigations;
