import { onError } from '@apollo/client/link/error';
import PATHS from '../../routes/paths';

const UNAUTHORIZED_MESSAGE = 'Unauthorized';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === UNAUTHORIZED_MESSAGE) {
        console.log('UNAUTHORIZED');
        const url = `${PATHS.REFRESH_TOKEN}?${window.location.pathname}`;
        return window.location.replace(url);
      } else {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });
  if (networkError) console.error(`[Network error]: ${networkError}`);
});
