import { gql } from '@apollo/client';

export const typeDefs = gql`
  type PagingSortingInput {
    field: String!
    order: String!
  }

  enum BagAccountantStatus {
    PAID_TO_MERCHANT
    PROCESSING
    REQUIRES_REFUND_ACTION
    REFUND_RESOLVED
  }
`;
