import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Navigations from '../navigations/Navigations';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { scrollTop } from '../../utils/common';

interface PageProps {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
}

const Page = ({ title = '', children }: PageProps) => {
  const location = useLocation();

  useEffect(() => {
    scrollTop();
  }, [location]);

  return (
    <Box sx={{ display: 'flex' }} id="layout">
      <CssBaseline />
      <Navigations title={title} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto'
        }}>
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default Page;
