import { gql } from '@apollo/client';

const adminProfileFields = `
    active
    email
    id  
    mobile
    name
`;

export const adminProfileRegistrationGqlMutation = gql`
  mutation adminProfileRegistration($name: String!, $email: String!, $mobile: String) {
    adminProfileRegistration(input: {
      name: $name,
      email: $email,
      mobile: $mobile
    }) {
      ${adminProfileFields}
    }
  }
`;

export const adminProfileUpdateGqlMutation = gql`
  mutation adminProfileUpdate($id: Int!, $name: String!, $email: String!, $mobile: String, $active: Boolean) {
    adminProfileUpdate(
      id: $id, 
      input: {
        name: $name,
        email: $email,
        mobile: $mobile,
        active: $active
      }
    ) {
      ${adminProfileFields}
    }
  }
`;

export const adminMyProfileGqlQuery = gql`
  query adminMyProfile {
    adminMyProfile {
      ${adminProfileFields}
    }
  }
`;

export const adminProfilesListGqlQuery = gql`
  query adminProfilesList {
      adminProfiles {
        ${adminProfileFields}
    }
  }
`;

export const adminGqlQuery = gql`
  query adminProfile($id: Int!) {
      adminProfile(id: $id) {
        ${adminProfileFields}
    }
  }
`;

export const adminRemoveGqlMutation = gql`
  mutation adminProfileRemove($id: Int!) {
    adminProfileRemove(id: $id)
  }
`;

export const adminResetPasswordGqlMutation = gql`
  mutation adminResetPassword($email: String!) {
    adminResetPassword(email: $email)
  }
`;
