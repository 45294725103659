import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

import PageLoading from 'components/page-loading/PageLoading';
import Page from 'components/page/Page';

import PATHS from './paths';
import App from '../App';
import { AuthProvider } from '../hooks/useAuth';

const Home = lazy(() => import('pages/dashboard/Dashboard'));

const Categories = lazy(() => import('pages/categories/Categories'));
const CategoryView = lazy(() => import('pages/category-view/CategoryView'));

const Customers = lazy(() => import('pages/customers/Customers'));
const CustomerProfile = lazy(
  () => import('pages/customer-profile/CustomerProfile')
);
const Merchants = lazy(() => import('pages/merchants/Merchants'));
const MerchantProfile = lazy(
  () => import('pages/merchant-profile/MerchantProfile')
);

const Products = lazy(() => import('pages/products/Products'));
const ProductsDetails = lazy(
  () => import('pages/product-details/ProductDetails')
);
const Orders = lazy(() => import('pages/orders/Orders'));
const OrderDetails = lazy(() => import('pages/order-details/OrderDetails'));

const FAQ = lazy(() => import('pages/faq/FaqList'));
const FAQDetails = lazy(() => import('pages/faq-details/FaqDetails'));

const Tickets = lazy(() => import('pages/tickets/Tickets'));

const PageNotFound = lazy(() => import('pages/not-found/PageNotFound'));

const LoginPage = lazy(() => import('pages/login/Login'));
const ResetPasswordPage = lazy(() => import('pages/login/ResetPassword'));
const RefreshPage = lazy(() => import('pages/refresh-token/RefreshToken'));

const ChangePassword = lazy(
  () => import('pages/change-password/ChangePassword')
);

const Reviews = lazy(() => import('pages/reviews/Reviews'));

const Admins = lazy(() => import('pages/admins/Admins'));
const AdminProfile = lazy(() => import('pages/admin-profile/AdminProfile'));

const getPageElement = (
  Component: React.ElementType,
  title?: string
): React.ReactNode => (
  <Suspense fallback={<PageLoading />}>
    <Page title={title}>
      <Component />
    </Page>
  </Suspense>
);

const routes: RouteObject[] = [
  {
    path: PATHS.LOGIN,
    element: (
      <Suspense fallback={<PageLoading />}>
        <LoginPage />
      </Suspense>
    )
  },
  {
    path: PATHS.RESET_PASSWORD,
    element: (
      <Suspense fallback={<PageLoading />}>
        <ResetPasswordPage />
      </Suspense>
    )
  },
  {
    path: PATHS.REFRESH_TOKEN,
    element: getPageElement(RefreshPage, 'app.dashboard')
  },
  {
    path: PATHS.CHANGE_PASSWORD,
    element: getPageElement(ChangePassword, 'app.change_password')
  },
  {
    path: PATHS.CATEGORIES.TOP,
    element: getPageElement(Categories, 'app.categories')
  },
  {
    path: PATHS.CATEGORIES.CHILDREN,
    element: getPageElement(CategoryView, 'app.categories')
  },
  { path: PATHS.NOT_FOUND, element: getPageElement(PageNotFound, '404') },
  { path: PATHS.DASHBOARD, element: getPageElement(Home, 'app.dashboard') },
  {
    path: PATHS.CUSTOMERS.LIST,
    element: getPageElement(Customers, 'app.customers')
  },
  {
    path: PATHS.CUSTOMERS.PROFILE,
    element: getPageElement(CustomerProfile, 'app.customers')
  },
  {
    path: PATHS.MERCHANTS.LIST,
    element: getPageElement(Merchants, 'app.merchants')
  },
  {
    path: PATHS.MERCHANTS.PROFILE,
    element: getPageElement(MerchantProfile, 'app.merchants')
  },
  {
    path: PATHS.PRODUCTS.LIST,
    element: getPageElement(Products, 'app.products')
  },
  {
    path: PATHS.PRODUCTS.DETAILS,
    element: getPageElement(ProductsDetails, 'app.products')
  },
  {
    path: PATHS.ORDERS.LIST,
    element: getPageElement(Orders, 'app.orders')
  },
  {
    path: PATHS.ORDERS.DETAILS,
    element: getPageElement(OrderDetails, 'app.orders')
  },
  { path: PATHS.FAQ.LIST, element: getPageElement(FAQ, 'app.FAQ') },
  { path: PATHS.FAQ.DETAILS, element: getPageElement(FAQDetails, 'app.FAQ') },
  { path: PATHS.REVIEWS, element: getPageElement(Reviews, 'app.reviews') },
  {
    path: PATHS.ADMINS.LIST,
    element: getPageElement(Admins, 'app.administrators')
  },
  {
    path: PATHS.ADMINS.PROFILE,
    element: getPageElement(AdminProfile, 'app.administrators')
  },
  {
    path: PATHS.TICKETS,
    element: getPageElement(Tickets, 'app.reports')
  }
];

export default createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    children: routes
  }
]);
