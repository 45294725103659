import { Fade, Menu, MenuItem } from '@mui/material';
import { t } from '../../../../common/languages/i18n';
import React, { useState } from 'react';
import { NoAccounts, PermContactCalendar } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { selectAuthState } from '../../../../store/selects';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../../../routes/paths';

const UserMenu = () => {
  const authContext = useAuth();
  const authState = useSelector(selectAuthState);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    handleClose();
    navigate(PATHS.CHANGE_PASSWORD);
  };

  const handleLogout = () => {
    handleClose();
    authContext?.logout();
  };

  return (
    <>
      <IconButton
        sx={{
          fontSize: '1.5em',
          color: (theme) => theme.palette.primary.contrastText,
          ml: 3
        }}
        id="usermenu-button"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open}
        onClick={handleOpen}>
        <PermContactCalendar />
      </IconButton>
      <Menu
        id="user-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        slotProps={{
          root: { sx: { maxWidth: 300 } },
          paper: { sx: { maxWidth: 300, minWidth: 120 } }
        }}>
        <MenuItem disabled sx={{ justifyContent: 'center' }}>
          {authState.user?.name ?? <NoAccounts />}
        </MenuItem>
        <MenuItem onClick={handleChangePassword} className="capitalize">
          {t('app.change_password')}
        </MenuItem>
        <MenuItem onClick={handleLogout} className="capitalize">
          {t('app.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
