import { configureStore } from '@reduxjs/toolkit';

import appReducer from 'store/app/appSlice';
import authReducer from 'store/auth/authSlice';
import { useDispatch } from 'react-redux';
import config from '../config';

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer
  },
  devTools: config.debug
});

export type RootState = ReturnType<typeof store.getState>;
export const useStoreDispatch = () => useDispatch<typeof store.dispatch>();
export default store;
