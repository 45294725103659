import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/user';

export interface AuthState {
  token?: string;
  user?: User;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {} as AuthState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    onLogout: (state) => {
      state.user = undefined;
      state.token = undefined;
    }
  }
});

export const { setToken, setUser, onLogout } = authSlice.actions;

export default authSlice.reducer;
