const config: {
  apiUrl: string;
  i18nDebug: boolean;
  debug: boolean;
  guestToken: string;
  icelandicLanguageId: number;
  englishLanguageId: number;
} = {
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost',
  i18nDebug: process.env.REACT_APP_I18N_DEBUG === 'true',
  debug: process.env.NODE_ENV === 'development',
  guestToken: process.env.REACT_APP_GUEST_TOKEN || '',
  englishLanguageId: Number(process.env.LANGUAGE_ID_ENGLISH || 1),
  icelandicLanguageId: Number(process.env.LANGUAGE_ID_ICELANDIC || 2)
};

export default config;
