import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import useLanguage from '../../../../hooks/useLangauge';
import {
  Language,
  LanguageFlags,
  LanguageTitles
} from '../../../../types/language';
import { Icon, Tooltip } from '@mui/material';
import { t } from '../../../../common/languages/i18n';
import React from 'react';
import Box from '@mui/material/Box';
import styles from './LanguageSwitcher.module.scss';

const LanguageSwitcher = () => {
  const { language, setCurrentLanguage } = useLanguage();

  const changeLanguage = (lang: string) => {
    setCurrentLanguage(lang as Language);
  };

  const isCurrent = (lang: Language) => {
    return language === lang;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'flex-start'
      }}>
      {Object.values(Language).map((languageKey) => (
        <Tooltip
          title={t(LanguageTitles[languageKey])}
          key={`language-${languageKey}`}>
          <Icon
            onClick={() => {
              changeLanguage(languageKey);
            }}
            className={`${styles.languageFlag} ${isCurrent(languageKey as Language) ? styles.selected : ''}`}>
            {getUnicodeFlagIcon(LanguageFlags[languageKey])}
          </Icon>
        </Tooltip>
      ))}
    </Box>
  );
};

export default LanguageSwitcher;
