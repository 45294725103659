import { enUS, isIS, Localization } from '@mui/material/locale';

export enum Language {
  EN = 'en',
  IS = 'is'
}

export const LanguageFlags: { [key in Language]: string } = {
  [Language.EN]: 'us',
  [Language.IS]: 'is'
};

export const LanguageTitles: { [key in Language]: string } = {
  [Language.EN]: 'app.english',
  [Language.IS]: 'app.icelandic'
};

export const LanguageToMuiLocale: { [key in Language]: Localization } = {
  [Language.EN]: enUS,
  [Language.IS]: isIS
};
