import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import styles from './PageLoading.module.scss';

const PageLoading = () => {
  return (
    <Box className={styles.pageLoader}>
      <LinearProgress color="secondary" variant="query" />
    </Box>
  );
};

export default PageLoading;
