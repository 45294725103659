import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import config from '../config';
import { authMiddleware } from './middlewares/auth';
import { errorLink } from './middlewares/error';
import { typeDefs } from './type-defs';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const uri = `${config.apiUrl}/api`;
const uploadLink = createUploadLink({ uri });

export const apolloClient = new ApolloClient({
  link: from([authMiddleware, errorLink, uploadLink]),
  cache: new InMemoryCache(),
  connectToDevTools: config.debug,
  typeDefs
});
