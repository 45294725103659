import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import Router from 'routes/Router';
import { Provider } from 'react-redux';
import store from './store/store';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './apollo/client';
import './styles.scss';
import { CookiesProvider } from 'react-cookie';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <RouterProvider router={Router} />
        </ApolloProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);
