import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { DRAWER_WIDTH } from '../../../config/const';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Copyright from './copyright/Copyright';
import Box from '@mui/material/Box';
import { MainMenu } from './main-menu/MainMenu';
import logo from 'assets/logo_small.png';
import styles from './LeftMenu.module.scss';

interface LeftMenuProps {
  open: boolean;
  toggleDrawer: () => void;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    })
  }
}));

const LeftMenu = ({ open, toggleDrawer }: LeftMenuProps) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [1]
        }}>
        <img src={logo} width="106" className={styles.logo} />
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <MainMenu />
      <Box
        sx={{
          marginTop: 'auto',
          paddingY: '15px'
        }}>
        <Copyright isShort={!open} />
      </Box>
    </Drawer>
  );
};

export default LeftMenu;
