import { gql } from '@apollo/client';

export const signInGqlMutation = gql`
  mutation signIn($email: String!, $password: EncryptedPasswordInput!) {
    signIn(input: { email: $email, password: $password }) {
      accessToken
      refreshToken
    }
  }
`;

export const refreshTokenGqlMutation = gql`
  mutation refreshToken {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;

export const signOutGqlMutation = gql`
  mutation signOut {
    signOut
  }
`;

export const adminProfileChangePasswordGqlMutation = gql`
  mutation adminProfileChangePassword(
    $newPassword: EncryptedPasswordInput!
    $oldPassword: EncryptedPasswordInput!
  ) {
    adminProfileChangePassword(
      newPassword: $newPassword
      oldPassword: $oldPassword
    )
  }
`;
