import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'common/languages/i18n';
import { Language } from '../types/language';
import { setLanguage } from 'store/app/appSlice';
import { selectAppState } from '../store/selects';

const useLanguage = () => {
  const dispatch = useDispatch();

  const { language } = useSelector(selectAppState);

  const setCurrentLanguage = useCallback((language: Language) => {
    void i18n.changeLanguage(language);
    dispatch(setLanguage(language));
  }, []);

  return { language, setCurrentLanguage };
};

export default useLanguage;
