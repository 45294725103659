import { ApolloLink } from '@apollo/client';
import store from '../../store/store';

export const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = store.getState().auth;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      authorization: `Bearer ${token}`,
      'Apollo-Require-Preflight': 'true',
      ...headers
    }
  }));

  return forward(operation);
});
