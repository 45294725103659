import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  Groups2 as CustomersIcon,
  Store as StoreIcon,
  ShoppingCartCheckout as ShoppingCartCheckoutIcon,
  LiveHelp as LiveHelpIcon,
  QrCode2 as QrCode2Icon,
  Category as CategoryIcon,
  MarkUnreadChatAlt,
  AdminPanelSettings,
  Report
} from '@mui/icons-material';
import PATHS from '../../../../routes/paths';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

interface MainMenuItem {
  path: string;
  title: string;
  icon: React.JSX.Element;
}

export const MainMenu = () => {
  const { pathname: currentPath } = useLocation();
  const { t } = useTranslation();

  const isCurrent = (path: string): boolean => currentPath.startsWith(path);

  const menuItems: MainMenuItem[] = [
    {
      path: PATHS.DASHBOARD,
      title: 'app.dashboard',
      icon: <DashboardIcon />
    },
    {
      path: PATHS.CUSTOMERS.LIST,
      title: 'app.customers',
      icon: <CustomersIcon />
    },
    {
      path: PATHS.MERCHANTS.LIST,
      title: 'app.merchants',
      icon: <StoreIcon />
    },
    {
      path: PATHS.PRODUCTS.LIST,
      title: 'app.products',
      icon: <QrCode2Icon />
    },
    {
      path: PATHS.CATEGORIES.TOP,
      title: 'app.categories',
      icon: <CategoryIcon />
    },
    {
      path: PATHS.ORDERS.LIST,
      title: 'app.orders',
      icon: <ShoppingCartCheckoutIcon />
    },
    {
      path: PATHS.FAQ.LIST,
      title: 'app.FAQ',
      icon: <LiveHelpIcon />
    },
    {
      path: PATHS.REVIEWS,
      title: 'app.reviews',
      icon: <MarkUnreadChatAlt />
    },
    {
      path: PATHS.TICKETS,
      title: 'app.reports',
      icon: <Report />
    },
    {
      path: PATHS.ADMINS.LIST,
      title: 'app.administrators',
      icon: <AdminPanelSettings />
    }
  ];

  return (
    <List component="nav">
      {menuItems.map((item) => (
        <ListItemButton
          key={`menu-${item.title}`}
          selected={isCurrent(item.path)}
          to={item.path}
          component={Link}>
          <Tooltip title={t(item.title)} placement="right">
            <ListItemIcon>{item.icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={t(item.title)} />
        </ListItemButton>
      ))}
    </List>
  );
};
