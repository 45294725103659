export const DRAWER_WIDTH: number = 240;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^(\+354)?[0-9]{7}$/;

export const COOKIE_NAME_REFRESH_TOKEN = 'rt';
export const COOKIE_NAME_ACCESS_TOKEN = 'at';

export const TIME_TO_REFRESH_EXPIRED_TOKEN = 60 * 60;
export const ONE_HOUR = 60 * 60;

export const NEW_ITEM_KEY = 'new';
