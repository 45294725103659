import { createTheme } from '@mui/material';
import colors from './colors.scss';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    background: {
      default: colors.background
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            marginRight: 0
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '90%',
          marginBottom: '1em'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td, &:last-child th': { border: 0 }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          textTransform: 'capitalize'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          textTransform: 'capitalize'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        gutters: {
          '.MuiCheckbox-root': {
            padding: '1px 9px'
          }
        }
      }
    }
  }
});
