import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { DRAWER_WIDTH } from '../../../config/const';
import LanguageSwitcher from './language-switcher/LanguageSwither';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import UserMenu from './user-menu/UserMenu';
import Box from '@mui/material/Box';

interface TopMenuProps {
  title?: string;
  open: boolean;
  toggleDrawer: () => void;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const TopMenu = ({ title = '', open, toggleDrawer }: TopMenuProps) => {
  const { t } = useTranslation();
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px' // keep right padding when drawer closed
        }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' })
          }}>
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, textTransform: 'capitalize', mx: 3 }}>
          {t(title)}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <LanguageSwitcher />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
