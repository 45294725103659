import config from '../config';

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

export const formatPrice = (
  price: number | string,
  locale = 'is-IS'
): string => {
  const NumberFormated = Number(price).toLocaleString(locale, {
    style: 'decimal'
  });
  return NumberFormated.replace(/,/g, '.');
};

export const compareValues =
  (key: string, order = 'asc') =>
  // @ts-expect-error any type
  (a, b) => {
    if (
      !Object.prototype.hasOwnProperty.call(a, key) ||
      !Object.prototype.hasOwnProperty.call(b, key)
    ) {
      return 0;
    }
    const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };

export const getGuestToken = async (): Promise<string | undefined> => {
  const requestOptions = {
    mode: 'cors' as RequestMode,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ guestToken: config.guestToken })
  };
  try {
    const response = await fetch(`${config.apiUrl}/getToken`, requestOptions);
    const data: { accessToken: string } = await response.json();
    return data.accessToken;
  } catch (err) {
    console.log(err);
  }
};
