import React from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from './hooks/useAuth';
import PATHS from './routes/paths';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './common/mui/mui-theme';
import { ThemeProvider } from '@mui/material';
import { LanguageToMuiLocale } from './types/language';
import { useSelector } from 'react-redux';
import { selectAppState } from './store/selects';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';
import { red } from '@mui/material/colors';

function App(): React.JSX.Element {
  const location = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();

  const authContext = useAuth();

  const { language } = useSelector(selectAppState);
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, LanguageToMuiLocale[language]),
    [language]
  );

  const NO_NEED_REFRESH_TOKEN_PAGES = [PATHS.LOGIN, PATHS.RESET_PASSWORD];

  useEffect(() => {
    authContext?.checkUserToken(
      NO_NEED_REFRESH_TOKEN_PAGES.includes(location.pathname)
    );
    if (!outlet) {
      navigate(PATHS.DASHBOARD);
    }
  }, [location]);

  const confirmDefaultOptions: ConfirmOptions = {
    dialogProps: {
      maxWidth: 'xs'
    },
    dialogActionsProps: {
      sx: { justifyContent: 'center', gap: 2 }
    },
    confirmationButtonProps: {
      variant: 'text',
      sx: { '&:hover': { backgroundColor: red[500], color: 'white' } }
    },
    cancellationButtonProps: {
      variant: 'outlined'
    }
  };

  return (
    <>
      <ThemeProvider theme={themeWithLocale}>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <ConfirmProvider defaultOptions={confirmDefaultOptions}>
            <CssBaseline />
            {outlet}
          </ConfirmProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
