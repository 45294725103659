import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'common/languages/locales/en.json';
import is from 'common/languages/locales/is.json';
import config from '../../config';

import LanguageDetector from 'i18next-browser-languagedetector';

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'is'],
    resources: {
      en: { translation: en },
      is: { translation: is }
    },
    interpolation: {
      escapeValue: false
    },
    debug: config.i18nDebug
  });

export default i18n;
export const t = i18n.t;
