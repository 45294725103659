import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../types/user';
import { useCookies } from 'react-cookie';
import {
  COOKIE_NAME_ACCESS_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN
} from '../config/const';
import React from 'react';
import PATHS from '../routes/paths';
import { jwtDecode } from 'jwt-decode';
import { add } from 'date-fns';
import { apolloClient } from '../apollo/client';
import {
  refreshTokenGqlMutation,
  signOutGqlMutation
} from '../apollo/gql/auth';
import { useDispatch, useSelector } from 'react-redux';
import { onLogout, setToken, setUser } from '../store/auth/authSlice';
import { adminMyProfileGqlQuery } from '../apollo/gql/admin';
import { selectAuthState } from '../store/selects';

export interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

export interface AuthContextType {
  token?: string;
  user?: User;
  onLogin: (data: AuthTokens) => void;
  logout: () => void;
  checkUserToken: (isNoNeedRefresh?: boolean) => void;
  refreshToken: () => void;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = (
  props: AuthProviderProps
): React.JSX.Element => {
  const [cookie, setCookie, removeCookie] = useCookies([
    COOKIE_NAME_ACCESS_TOKEN,
    COOKIE_NAME_REFRESH_TOKEN
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const refreshToken = async (hard = false): Promise<void> => {
    const refreshToken = cookie[COOKIE_NAME_REFRESH_TOKEN];
    if (refreshToken) {
      try {
        const res = await apolloClient.mutate({
          mutation: refreshTokenGqlMutation,
          context: {
            headers: {
              Authorization: `Bearer ${refreshToken}`
            }
          }
        });
        void onLogin(res.data.refreshToken);
      } catch (e) {
        console.log('error on refresh token', e);
        if (hard) {
          logout();
        }
      }
    } else if (hard) {
      logout();
    }
  };

  const getUserInfo = async (): Promise<void> => {
    try {
      const res = await apolloClient.query<{ adminMyProfile: User }>({
        query: adminMyProfileGqlQuery,
        fetchPolicy: 'no-cache'
      });
      const profile = res.data.adminMyProfile;
      if (!profile.active) {
        return logout();
      }
      dispatch(setUser(profile));
    } catch (e) {
      console.log('error on getting user info', e);
    }
  };

  const storeTokenToCookie = (
    token: string,
    cookieName:
      | typeof COOKIE_NAME_ACCESS_TOKEN
      | typeof COOKIE_NAME_REFRESH_TOKEN
  ): void => {
    const dateInHour = add(new Date().getDate(), { hours: 1 }).getTime();
    const jwtData = jwtDecode(token);
    const exp = jwtData.exp ? jwtData.exp * 1000 : dateInHour;
    setCookie(cookieName, token, {
      expires: new Date(exp)
    });
  };

  const onLogin = (data: AuthTokens): void => {
    try {
      dispatch(setToken(data.accessToken));
      storeTokenToCookie(data.accessToken, COOKIE_NAME_ACCESS_TOKEN);
      storeTokenToCookie(data.refreshToken, COOKIE_NAME_REFRESH_TOKEN);
      void getUserInfo();
    } catch (e) {
      console.log('error onLogin', e);
    }
  };

  const logout = (): void => {
    try {
      if (authState.token) {
        void apolloClient.mutate({
          mutation: signOutGqlMutation
        });
      }
    } catch (e) {
      console.log('error on signOut request', e);
    }
    removeCookie(COOKIE_NAME_ACCESS_TOKEN);
    removeCookie(COOKIE_NAME_REFRESH_TOKEN);
    dispatch(onLogout());
    return navigate(PATHS.LOGIN, { replace: true });
  };

  const checkUserToken = async (isNoNeedRefresh = false): Promise<void> => {
    const accessToken = cookie[COOKIE_NAME_ACCESS_TOKEN];
    if (!accessToken && !isNoNeedRefresh) {
      await refreshToken(true);
    }

    if (accessToken) {
      let jwtData;
      try {
        jwtData = jwtDecode(accessToken);
      } catch (e) {
        console.log('error on decoding token', e);
        logout();
      }
      dispatch(setToken(accessToken));
      const now = new Date().getTime();
      const exp = (jwtData?.exp ?? 1) * 1000;
      if (exp > now) {
        if (!authState.user) {
          await getUserInfo();
        }
      } else {
        await refreshToken();
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{ onLogin, logout, checkUserToken, refreshToken }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
