const PATHS = {
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  RESET_PASSWORD: '/reset-password',
  REFRESH_TOKEN: '/refresh',
  NOT_FOUND: '*',
  DASHBOARD: '/dashboard',
  ADMINS: {
    LIST: '/admins',
    PROFILE: '/admins/:id'
  },
  CATEGORIES: {
    TOP: '/categories',
    CHILDREN: '/categories/:id'
  },
  CUSTOMERS: {
    LIST: '/customers',
    PROFILE: '/customers/:id'
  },
  MERCHANTS: {
    LIST: '/merchants',
    PROFILE: '/merchants/:id'
  },
  PRODUCTS: {
    LIST: '/products',
    DETAILS: '/products/:id'
  },
  ORDERS: {
    LIST: '/orders',
    DETAILS: '/orders/:id'
  },
  FAQ: {
    LIST: '/faq',
    DETAILS: '/faq/:id'
  },
  TICKETS: '/tickets',
  REVIEWS: '/reviews'
};

export default PATHS;
